import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.zh-TW.min';

var wForm = {
  init: function() {
    var me = this;

    $('.component.form').each(function() {
      var $panel = $(this);
      var blockID = $panel.data('id');
      var compoName = $panel.attr('class').replace(' ', '-') + '-' + blockID;

      // get correspondent data
      var blockDat = window.event_dat['form' + blockID];
      blockDat['componentID'] = blockID;
      blockDat.raw = JSON.parse(blockDat.raw);

      blockDat.raw.checkLimit = (blockDat.raw.total_limit > -1) ? '1' : '0';

      var $form = me.renderForm(blockDat.raw).appendTo($panel.find('.form'));
      $form = $form.filter('form');

      if (blockDat.raw.checkLimit) {
        me.checkLimit(blockDat.id).done(function(reachLimit) {
          if (reachLimit) {
            $form.addClass('reachlimit');
            $form.find('input').prop('disabled', true);
            $form.find('select').prop('disabled', true);
          }
        });
      };

      me.initSubmit($form);
      me.initUploadFile($form);
      me.initInputDatePicker($form);
    });
  },

  renderForm: function(raw) {
    var $form = $(require('../../../templates/events/component_form/form')(raw));
    var $controls = $form.find('.controls');

    // append controls to form
    var len = Object.keys(raw.fields).length;
    for (var i = 1; i <= len; i++) {
      var key = 'field' + i;
      var ele = raw.fields[key];
      ele.key = key;
      ele.field = i;
      ele.formid = raw.id;
      ele.hashlbl = wUtil.getStringHash(ele.label);

      if (ele.type == 'select') {
        ele.options = ele.options.map(function(opt) {
          return {
            'val': opt,
            'hash': wUtil.getStringHash(opt)
          }
        });
      }

      if (ele.type == 'file') {
        if (!ele.file_types) ele.file_types = '.jpg .jpeg .png';
        ele.accept = ele.file_types.replaceAll(' ', ',');
      }

      $(require('../../../templates/events/component_form/f_' + ele.type)(ele)).appendTo($controls);
    };

    // append previous answer to form controls
    var fillForm = function($form, $controls) {
      if (!raw.editable || !raw.active_user) return;

      var answerKeys = Object.keys(raw.active_user);
      if (!answerKeys.length) return;

      answerKeys.forEach(function(key) {
        var $control = $controls.find('[data-label=' + wUtil.getStringHash(key) + ']');
        if (!$control.length) return;
        var nodeType = $control[0].nodeName;

        // check if input[type=file] exist
        var $controlId = $control.attr('id');
        var $uploadFileBtn = $form.find('[data-rel-input=' + $controlId + ']');

        if ($uploadFileBtn.length) {
          // create a new File object (fake object just for view)
          var prevFile = raw.active_user[key];
          if (prevFile) {
            var $prevFileDom = $uploadFileBtn.parents('label').find('.prev-file');
            if (prevFile.match(/\.png$|\.jpg$|\.jpeg$/g)) {
              $prevFileDom.append(`<img src="${prevFile}">`);
            } else {
              var prevFileName = prevFile.replace(/.*\//, '');
              $prevFileDom.append(`<a href="${prevFile}" target="_blank">${prevFileName}</a>`);
            }
          }
          var fakeFile = new File([""], "", {
            type: 'image/png',
            lastModified: new Date()
          })
          // create a DataTransfer to get a FileList
          var dataTransfer = new DataTransfer();
          dataTransfer.items.add(fakeFile);
          $uploadFileBtn[0].files = dataTransfer.files;
        }

        if ((nodeType == 'INPUT'))
          $control.attr('value', raw.active_user[key]);
        else if (nodeType == 'SELECT')
          $control.children('option[data-hash=' + wUtil.getStringHash(raw.active_user[key]) + ']').prop('selected', true);
      });

      $form.find('.privacy-term input').prop('checked', true);
    }
    fillForm($form, $controls);

    return $form;
  },

  checkLimit: function(formID) {
    var dfd = new $.Deferred();
    $.get(womany.api + '/forms/' + formID + '/limit').done(function(limit) {
      dfd.resolve(limit.total_limit >= 0 && (limit.total_limit <= limit.total_users));
    });
    return dfd.promise();
  },

  initUploadFile: function($form) {
    var $uploadBtns = $form.find('input[type=file]');

    $uploadBtns.on('change', function(e) {
      var $uploadBtn = $(e.target);
      var field = $uploadBtn.attr('data-field');
      var inputFieldId = $uploadBtn.attr('data-rel-input');
      var inputFile = $uploadBtn[0].files[0];
      var $prevFileDom = $uploadBtn.parents('label').find('.prev-file');
      var fileType = inputFile['type'];
      var accept = $uploadBtn.attr('accept');
      var file_types = $uploadBtn.attr('data-filetypes');

      var show_file_types_error = function() {
        $prevFileDom.html(`<p class="wrong-type">只能接受 ${file_types} 檔案</p>`);
      };

      /*
        accept = ".png,.jpg,.tiff,.pdf"
        fileType = 'image/png', 'image/tiff', 'application/pdf'...etc

        special case:
        .ai - application/postscript
      */

      // check filetype against accept attribute
      fileType = fileType.split('/');

      if (fileType[1] == 'postscript') {
        fileType[1] = 'ai';
      }

      if (accept.includes(fileType[0]) || accept.includes(fileType[1])) {
        $prevFileDom.html('');
      } else {
        show_file_types_error();
        return;
      }

      var formData = new FormData();
      if (inputFile) {
        var $submitBtn = $form.find('input[type=submit]');
        var $progressDom = $uploadBtn.parents('label').find('.progress');
        $submitBtn.attr('disabled', true);
        $progressDom.show();
        formData.append('file', inputFile);

        $.ajax({
          xhr: function() {
            var xhr = new window.XMLHttpRequest();
            // Upload progress
            xhr.upload.addEventListener("progress", function(progressEvent) {
              if (progressEvent.lengthComputable) {
                var percentComplete = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2);
                if (percentComplete == 100) {
                  $progressDom.hide();
                  return
                }
                $progressDom.find('.percent').text(percentComplete + '%');
              }
            }, false);
            return xhr;
          },
          method: 'POST',
          url: womany.api + '/forms/'+ $form.attr('data-id') + '/field' + field + '/assets',
          data: formData,
          xhrFields: { withCredentials: true },
          contentType: false,
          processData: false,
          cache: false,
        })
          .done(function(res) {
            $('#' + inputFieldId).val(res.data.url);
            $submitBtn.attr('disabled', false);

            var preview_item = '';
            if (res.data.url.match(/\.(a?png|jpe?g|avif|gif|svg|webp)$/)) {
              preview_item = `<img src="${res.data.url}">`;
            } else {
              preview_item = res.data.url.replace(/.*\//, '');
            }
            $prevFileDom.html(`<a title="預覽上傳檔案（開新視窗）" href="${res.data.url}" target="_blank">${preview_item}</a>`);
          })
          .fail(function() {
            show_file_types_error();
          })
      }
    });
  },

  initSubmit: function($form) {
    var me = this;

    function submitData($form) {
      $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        data: $form.serialize(),
        xhrFields: { withCredentials: true }
      })
        .done(function(dat) {
          if (dat.code && dat.code == 'success.') {
            $form.removeClass('error').addClass('submitted');
            $form.find('input').prop('disabled', true);
            $form.find('select').prop('disabled', true);
            var componentId = $form.parents('.component.form').attr('id');
            me.scrollTo($("#" + componentId));
            return;
          };

          var errorDesc = '';

          for (var error in dat.errors) {
            if (Array.isArray(dat.errors[error])) {
              if (errorDesc.length) errorDesc += '；';
              errorDesc += dat.errors[error][0];
            };
          };
          if (errorDesc.length) errorDesc = ' (' + errorDesc + ')';

          var $error = $form.children('.error');
          $error.text($error.text().replace('$errormsg', errorDesc));
          $form.addClass('error');
        });
    };

    $form.on('submit', function(ev) {
      var id = $form.data('id');

      // Get limited numbers
      if ($form.data('checklimit')) {
        me.checkLimit(id).done(function(reachLimit) {
          if (reachLimit) {
            $form.addClass('reachlimit');
            $form.find('input').prop('disabled', true);
            $form.find('select').prop('disabled', true);
          } else {
            submitData($form);
          }
        });
        return false;
      };

      submitData($form);
      return false;
    });
  },

  initInputDatePicker: function($form) {
    var html5DatePicker = function() {
      var tmp = document.createElement("input");
      tmp.setAttribute("type", "date");
      return tmp.type === "date";
    }();

    if (html5DatePicker) {
      $form.addClass('html5DatePick');
      return;
    };

    $form.find('input[type=date]').datepicker({
      format: "yyyy-mm-dd",
      autoclose: true,
      language: 'zh-TW',
      endDate: new Date(),
      startView: 'decade'
    });
  },
  scrollTo: function($target) {
    var me = this;
    if (!$target.length) return;

    // check if #w-header exist and navbar height
    var pt = $('#w-header').length ? $('#w-header').outerHeight() : 0;
    var $topMenu = $('.component.menu, .component.navbar, .component.nested_navbar');
    if ($topMenu.data('fixedpos')) pt += $topMenu.outerHeight();

    $('html, body').animate({ scrollTop: $target.offset().top - pt }, 600);
  }
}.init();
