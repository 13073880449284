var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "<form data-id=\""
    + alias5(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" method=\"post\" action=\""
    + alias5(((helper = (helper = helpers.actionUrl || (depth0 != null ? depth0.actionUrl : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"actionUrl","hash":{},"data":data}) : helper)))
    + "\" data-checklimit=\""
    + alias5(((helper = (helper = helpers.checkLimit || (depth0 != null ? depth0.checkLimit : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"checkLimit","hash":{},"data":data}) : helper)))
    + "\">\n  <h2 class=\"component-title\">"
    + alias5(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n  <h2>"
    + alias5(((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"subtitle","hash":{},"data":data}) : helper)))
    + "</h2>\n\n  <div class=\"controls\"></div>\n\n  <label class=\"privacy-term\"><input type=\"checkbox\" required>我已詳閱並同意<a href=\"https://womany.net/data-privacy\" target=\"_blank\">個資法條款</a></label>\n  <input type=\"submit\" value=\""
    + alias5(((helper = (helper = helpers.button_text || (depth0 != null ? depth0.button_text : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"button_text","hash":{},"data":data}) : helper)))
    + "\"></input>\n\n  <p class=\"msg error\">發生錯誤，請檢查一下：$errormsg</p>\n  <p class=\"msg reachlimit\">活動額滿囉！期待下次有你參與。</p>\n  <p class=\"msg submitted\">資料成功送出囉！</p>\n</form>\n<div class=\"info\">"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});